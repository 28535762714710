/* global rangeFilterComponent */
class ModaRangeFilterComponent extends rangeFilterComponent {
    getWatch() {
        const superWatch = super.getWatch();
        superWatch['display'] = function (newValue, oldValue) {
            if(this.filterField && newValue) {
                this.emitEvent("filter-menu-open",this.filterField.ItemField);
            }
        };
        return superWatch;
    }

    mounted() {
        return async function () {
            let self = this;
            this.eventMenuClose = this.eventMenuClose.bind(this);
            this.subscribeEvent("filter-menu-open",this.eventMenuClose);
            this.generateIntervals();
            if (this.open_by_default != 'undefined') {
                this.display = this.open_by_default;
            } else {
                this.display = !this.isMobile;
                $(window).resize(function () {
                    self.isMobile = $(window).width() <= 560;
                    self.display = !self.isMobile;
                });
            }
        };
    }

    eventMenuClose(menuName) {
        if(this.filterField.ItemField !== menuName  && this.display)
            this.display = false;
    }

    getMethods() {
        const superMethods = super.getMethods();
        superMethods.eventMenuClose = this.eventMenuClose;
        return superMethods;
    }

    getTemplate() {
        return `<div class="filter" v-show="inView>0">
                    <p class="filter-title" @click="display = !display">
                        {{tr(filterField.ItemField)}}
                        <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                        <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                    </p>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile" >
                        <ul class="unstyled">
                          <template v-for="interval of filterIntervals">
                                <template v-if="interval[2].itemCount>0">
                                    <li :key="'li-'+interval[2].name" v-if="interval[2].name == intervalBegin" >
                                        <div class="custom-control custom-checkbox">
                                            <input :key="'input1-'+interval[2].name" class="custom-control-input" v-model="interval[2].active"  type="checkbox" :id="interval[2].name"  :name="interval[2].name" @click="launchFilter(interval)"/>
                                            <label :key="'label-'+interval[2].name"  :for="interval[2].name" class="custom-control-label">Hasta 
                                                 <template v-if="isCurrency">$</template>{{interval[1]}} ({{interval[2].itemCount}})
                                            </label>
                                        </div>
                                    </li>
                                    <li :key="'li-'+interval[2].name" v-else-if="interval[2].name == intervalEnd" >
                                        <div class="custom-control custom-checkbox">
                                            <input :key="'input2-'+interval[2].name" class="custom-control-input" v-model="interval[2].active" type="checkbox" :id="interval[2].name" :name="interval[2].name"  @click="launchFilter(interval)"/>
                                            <label :key="'label2-'+interval[2].name" :for="interval[2].name" class="custom-control-label" >Más de <template v-if="isCurrency">$</template>{{interval[1]}} ({{interval[2].itemCount}})</label>
                                        </div>
                                    </li>
                                    <li :key="'li-'+interval[2].name" v-else>
                                        <div class="custom-control custom-checkbox">
                                            <input :key="'input3-'+interval[2].name" class="custom-control-input" v-model="interval[2].active" type="checkbox" :id="interval[2].name" @click="launchFilter(interval)" />
                                            <label :key="'label3-'+interval[2].name" :for="interval[2].name" class="custom-control-label"><template v-if="isCurrency">$</template>{{interval[0]}} a <template v-if="isCurrency">$</template>{{interval[1]}} ({{interval[2].itemCount}})</label>
                                        </div>
                                    </li>
                                </template>
                          </template>
                        </ul>
                    </section>
                </div>`;
    }
}

ModaRangeFilterComponent.registerComponent();